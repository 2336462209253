import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  FormControl,
  Typography,
  TextField,
  Link,
  FormControlLabel,
  Box,
  Alert,
} from '@mui/material';
import axios from '../../utils/axios';
import { ConsignmentCancellation, ConsignmentCancellationStatus } from '../../typeORM';
import { API_URL } from '../../constants';
import Loader from '../../components/loader';
import LabelValuePairDisplay from './detailComponents/LabelValuePairDisplay';
import { StripedDataGrid } from '../../styledComponents/datagrid';
import {
  GridColDef,
  GridInputRowSelectionModel,
  GridRenderCellParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import { capitalizeFirstletter } from '../../utils/helper';
import { renderCellExpand } from '../../components/library/DataGrid/renderCellExpand';
import { Checkbox } from '../../components/library';

interface Props {
  submissionId: number;
  catalogProductId?: number;
  editable: boolean;
  callback: () => Promise<any>;
}

const ConsignmentCancellationForm: FC<Props> = ({
  submissionId,
  catalogProductId,
  editable,
  callback,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [shopifyStoreName, setShopifyStoreName] = useState();
  const [editingCancellation, setEditingCancellation] = useState<ConsignmentCancellation>();
  const [updatedCancellationStatus, setUpdatedCancellationStatus] = useState<string>('');
  const [shopifyDraftOrderId, setShopifyDraftOrderId] = useState<string>('');
  const [shopifyDraftOrderInvoiceUrl, setShopifyDraftOrderInvoiceUrl] = useState<string>('');
  const [buyersNote, setBuyersNote] = useState('');
  const [confirmStepComplete, setConfirmStepComplete] = useState(false);
  const [consignmentCancellations, setConsignmentCancellations] = useState<
    ConsignmentCancellation[]
  >([]);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridInputRowSelectionModel>();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [saveButtonText, setSaveButtonText] = useState('Save');
  const statusSelectOptions = Object.values(ConsignmentCancellationStatus).map(v => ({
    value: v,
    display: capitalizeFirstletter(v),
  }));
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    const fetchConsignmentCancellations = async () => {
      setLoading(true);
      const url = `${API_URL}/tradeup/syb/submission/${submissionId}/consignment-cancellation`;
      try {
        const response = await axios.get(url);
        if (!response || !response?.data) {
          setLoading(false);
          throw new Error(`got a response without data: ${JSON.stringify(response)}`);
        }
        setShopifyStoreName(response.data.shopifyStoreName);
        setConsignmentCancellations(response.data.consignmentCancellations);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchConsignmentCancellations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!!!editingCancellation?.id) {
        throw Error('nothing to edit');
      }
      const id = editingCancellation.id;
      const url = `${API_URL}/tradeup/syb/submission/${submissionId}/consignment-cancellation/${id}`;
      const requestBody = {
        cancellationStatus: updatedCancellationStatus,
        shopifyDraftOrderId: shopifyDraftOrderId,
        shopifyDraftOrderInvoiceUrl: shopifyDraftOrderInvoiceUrl,
        buyersNote: buyersNote,
      };
      const response = await axios.put(url, requestBody);
      if (!response || !response?.data) {
        setLoading(false);
        throw new Error(`update failed: ${JSON.stringify(response)}`);
      }
      setConsignmentCancellations([response.data]);
      if (response.data.cancellationStatus === ConsignmentCancellationStatus.REVOKED)
        await callback();
      setLoading(false);
      setUpdatedCancellationStatus('');
      setEditingCancellation(undefined);
      setErrorMsg(null);
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.response.data.message);
    }
  };

  useEffect(() => {
    setShopifyDraftOrderId(editingCancellation?.shopifyDraftOrderId || '');
    setShopifyDraftOrderInvoiceUrl(editingCancellation?.shopifyDraftOrderInvoiceUrl || '');
  }, [editingCancellation]);

  useEffect(() => {
    setSaveButtonText(`Move to ${capitalizeFirstletter(updatedCancellationStatus)}`);

    switch (updatedCancellationStatus) {
      case ConsignmentCancellationStatus.PENDING:
        setCanSubmit(!!shopifyDraftOrderId && !!shopifyDraftOrderInvoiceUrl);
        break;

      case ConsignmentCancellationStatus.VERIFIED:
      case ConsignmentCancellationStatus.COMPLETED:
        setCanSubmit(confirmStepComplete);
        break;

      case ConsignmentCancellationStatus.REVOKED:
        setCanSubmit(confirmStepComplete && !!buyersNote);
        break;

      default:
        setCanSubmit(true);
        break;
    }
  }, [
    buyersNote,
    confirmStepComplete,
    shopifyDraftOrderId,
    shopifyDraftOrderInvoiceUrl,
    updatedCancellationStatus,
  ]);

  const columns: GridColDef[] = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: () => ' ',
      renderCell: (params: GridRenderCellParams) => (
        <Typography>
          <Radio
            color="primary"
            value={params.id}
            checked={rowSelectionModel === params.id}
            onClick={() => setRowSelectionModel(params.id)}
          />{' '}
          {params.id}
        </Typography>
      ),
      flex: 1,
    },
    {
      headerName: 'Status',
      field: 'cancellationStatus',
      flex: 2,
    },
    {
      headerName: 'Address',
      field: 'address',
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2">
          <Typography>
            {params.row.firstName} {params.row.lastName}
          </Typography>
          <Typography>{params.row.address}</Typography>
          {params.row.address2 && <Typography>{params.row.address2}</Typography>}
          <Typography>
            {params.row.city}, {params.row.state} {params.row.zip} {params.row.country}
          </Typography>
        </Typography>
      ),
      flex: 4,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      flex: 2,
    },
    {
      headerName: 'Draft Order Id',
      field: 'shopifyDraftOrderId',
      flex: 2,
    },
    {
      headerName: 'Invoice Url',
      field: 'shopifyDraftOrderInvoiceUrl',
      flex: 4,
      renderCell: renderCellExpand,
    },
    {
      headerName: 'Created',
      field: 'created',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        const hoverValue = new Date(params.value).toLocaleString('en-US', {
          timeZone: 'America/Denver',
        });
        params.value = new Date(params.value).toLocaleDateString('en-US', {
          timeZone: 'America/Denver',
        });
        return renderCellExpand(params, hoverValue);
      },
    },
    {
      headerName: 'Modified',
      field: 'modified',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        const hoverValue = new Date(params.value).toLocaleString('en-US', {
          timeZone: 'America/Denver',
        });
        params.value = new Date(params.value).toLocaleDateString('en-US', {
          timeZone: 'America/Denver',
        });
        return renderCellExpand(params, hoverValue);
      },
    },
  ];

  return (
    <Grid container direction="row" justifyContent="space-evenly">
      <Loader loading={loading} />
      <Grid container justifyContent="space-between" alignItems="start" sx={{ mb: 2 }}>
        <Grid item xs={8}>
          {!!errorMsg && (
            <Typography component="span" color="error">
              {errorMsg}
            </Typography>
          )}
        </Grid>
        {!editingCancellation && editable && (
          <Grid item xs={4} sx={{ textAlign: 'right' }}>
            <Button
              color={loading ? 'info' : 'success'}
              variant="contained"
              disabled={loading || (consignmentCancellations.length > 1 && !!!rowSelectionModel)}
              endIcon={loading && <CircularProgress color={'inherit'} size={'16px'} />}
              onClick={() => {
                consignmentCancellations.length > 1
                  ? setEditingCancellation(
                      consignmentCancellations.find(cc => cc.id === rowSelectionModel),
                    )
                  : setEditingCancellation(consignmentCancellations[0]);
              }}
            >
              {loading ? 'Loading' : 'Edit'}
            </Button>
          </Grid>
        )}
      </Grid>

      {!!editingCancellation ? (
        <Grid container item xs={12} rowSpacing={5}>
          <Grid item xs={5}>
            <FormControl variant="outlined" style={{ maxWidth: '50%' }}>
              <InputLabel id="updateStatus">Update Status</InputLabel>
              <Select
                labelId="updateStatus"
                onChange={e => {
                  setUpdatedCancellationStatus(e.target.value ?? '');
                }}
                name="updateStatus"
                value={updatedCancellationStatus || ''}
                label="Update Status"
              >
                {statusSelectOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.display}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {!!updatedCancellationStatus && (
            <Grid item xs={6} textAlign="center">
              <Box>
                <Typography variant="body1" sx={{ lineHeight: 3.5 }}>
                  Update this Consignment Cancellation from{' '}
                  <Typography fontWeight="bold" fontFamily="bold" display="inline">
                    {editingCancellation.cancellationStatus}
                  </Typography>{' '}
                  to{' '}
                  <Typography fontWeight="bold" fontFamily="bold" display="inline">
                    {updatedCancellationStatus}
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          )}

          {updatedCancellationStatus === editingCancellation.cancellationStatus && (
            <Grid container item xs={12} rowSpacing={3}>
              <Grid item xs={12}>
                <Alert severity="warning" sx={{ mt: 1, width: '60%' }}>
                  <Typography variant="body1">
                    Warning: You are not going to change the status of the Consigment
                    Cancellation... You are going to trigger the backend updates, including sending
                    the Seller an email.
                  </Typography>
                </Alert>
              </Grid>
            </Grid>
          )}
          {updatedCancellationStatus === ConsignmentCancellationStatus.REQUESTED && (
            <Grid container item xs={12} rowSpacing={3}>
              <Grid item xs={12}>
                <Alert severity="warning" sx={{ mt: 1, width: '60%' }}>
                  <Typography variant="body1">
                    Warning: The seller will receive an email telling them the Consignment
                    Cancellation request has moved back to{' '}
                    <Typography fontWeight="bold" fontFamily="bold" display="inline">
                      Requested
                    </Typography>
                    `, this is likely to cause confusion. If you need to continue please reach out
                    to the seller to explain the situations
                  </Typography>
                </Alert>
              </Grid>
            </Grid>
          )}
          {updatedCancellationStatus === ConsignmentCancellationStatus.PENDING && (
            <Grid container item xs={12} rowSpacing={3}>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="overline">
                    Steps required to move a Consignment Cancellation to the{' '}
                    <Typography fontWeight="bold" fontFamily="bold" display="inline">
                      Pending
                    </Typography>{' '}
                    status
                  </Typography>
                </Box>
                <Box>
                  <Typography component="ul">
                    {!!catalogProductId && (
                      <Typography component="li" variant="overline">
                        Unpublish the item in Shopify via Item Catalog Item:
                        <Link href={`/catalog/products/${catalogProductId}`} target="_blank">
                          {catalogProductId}
                        </Link>
                      </Typography>
                    )}
                    {!!!editingCancellation.shopifyDraftOrderId ? (
                      <>
                        <Typography component="li" variant="overline">
                          Create the{' '}
                          <Link
                            href={`https://admin.shopify.com/store/${shopifyStoreName}/draft_orders/`}
                            target="_blank"
                          >
                            Draft Order
                          </Link>{' '}
                          in Shopify
                        </Typography>
                        <Typography component="li" variant="overline">
                          Capture the Draft Order Id from the URL
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography component="li" variant="overline">
                          Verify the{' '}
                          <Link
                            href={`https://admin.shopify.com/store/${shopifyStoreName}/draft_orders/${editingCancellation.shopifyDraftOrderId}`}
                            target="_blank"
                          >
                            Draft Order
                          </Link>{' '}
                          in Shopify is correct
                        </Typography>
                        <Typography component="li" variant="overline">
                          Verify the Draft Order Id is correct below
                        </Typography>
                      </>
                    )}
                    {!!!editingCancellation.shopifyDraftOrderInvoiceUrl ? (
                      <Typography component="li" variant="overline">
                        Capture the Draft Order Invoice URL from Shopify
                      </Typography>
                    ) : (
                      <Typography component="li" variant="overline">
                        Verify the Draft Order Invoice URL is correct below
                      </Typography>
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={2}>
                  <TextField
                    variant="outlined"
                    onChange={e => setShopifyDraftOrderId(e.currentTarget.value)}
                    value={shopifyDraftOrderId}
                    name="shopifyDraftOrderId"
                    placeholder="Id from the URL"
                    label="Shopify Draft Order Id"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    onChange={e => setShopifyDraftOrderInvoiceUrl(e.currentTarget.value)}
                    value={shopifyDraftOrderInvoiceUrl}
                    name="shopifyDraftOrderInvoiceUrl"
                    placeholder="Share > Copy Link"
                    label="Shopify Draft Order Invoice Url"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {updatedCancellationStatus === ConsignmentCancellationStatus.VERIFIED && (
            <Grid container item xs={12} rowSpacing={3}>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="overline">
                    Steps required to move a Consignment Cancellation to the{' '}
                    <Typography fontWeight="bold" fontFamily="bold" display="inline">
                      Verified
                    </Typography>{' '}
                    status
                  </Typography>{' '}
                </Box>
                <Box>
                  <Typography component="ul">
                    <Typography component="li" variant="overline">
                      Verify the{' '}
                      <Link
                        href={`https://admin.shopify.com/store/${shopifyStoreName}/draft_orders/${editingCancellation.shopifyDraftOrderId}`}
                        target="_blank"
                      >
                        Draft Order {editingCancellation.shopifyDraftOrderId}
                      </Link>
                    </Typography>
                  </Typography>{' '}
                </Box>
              </Grid>
              <Grid container item xs={12}>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confirmStepComplete}
                        onChange={(e: any) => {
                          const { checked } = e.target;
                          setConfirmStepComplete(checked);
                        }}
                        name="confirmPurchaseComplete"
                        color="primary"
                      />
                    }
                    name="confirmPurchaseComplete"
                    label="Purchase is complete"
                    labelPlacement="end"
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {updatedCancellationStatus === ConsignmentCancellationStatus.COMPLETED && (
            <Grid container item xs={12} rowSpacing={3}>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="overline">
                    Steps required to move a Consignment Cancellation to the{' '}
                    <Typography fontWeight="bold" fontFamily="bold" display="inline">
                      Completed
                    </Typography>{' '}
                    status
                  </Typography>{' '}
                </Box>
                <Box>
                  <Typography component="ul">
                    <Typography component="li" variant="overline">
                      Pick the return item
                    </Typography>
                    <Typography component="li" variant="overline">
                      Print the{' '}
                      <Link href={editingCancellation.shippingLabelUrl} target="_blank">
                        Shipping Label {editingCancellation.shopifyDraftOrderId}
                      </Link>
                    </Typography>
                    <Typography component="li" variant="overline">
                      Ship it
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
              <Grid container item xs={12}>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confirmStepComplete}
                        onChange={(e: any) => {
                          const { checked } = e.target;
                          setConfirmStepComplete(checked);
                        }}
                        name="confirmCompleteAction"
                        color="primary"
                      />
                    }
                    name="confirmCompleteAction"
                    label="Item has been shipped"
                    labelPlacement="end"
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {updatedCancellationStatus === ConsignmentCancellationStatus.REVOKED && (
            <Grid container item xs={12} rowSpacing={3}>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="overline">
                    Steps required to{' '}
                    <Typography fontWeight="bold" fontFamily="bold" display="inline">
                      Revoke
                    </Typography>{' '}
                    a Consignment Cancellation
                  </Typography>{' '}
                </Box>
                <Box>
                  <Typography component="ul">
                    <Typography component="li" variant="overline">
                      If the{' '}
                      <Link
                        href={`https://admin.shopify.com/store/${shopifyStoreName}/draft_orders/${editingCancellation.shopifyDraftOrderId}`}
                        target="_blank"
                      >
                        Draft Order {editingCancellation.shopifyDraftOrderId}
                      </Link>{' '}
                      has been paid, we need to refund the Seller in Shopify
                    </Typography>
                    <Typography component="li" variant="overline">
                      Repulish the item in Shopify
                      {!!catalogProductId && (
                        <>
                          {' '}
                          <Link href={`/catalog/products/${catalogProductId}`} target="_blank">
                            {catalogProductId}
                          </Link>
                        </>
                      )}
                    </Typography>
                    <Typography component="li" variant="overline">
                      In Netsuite find the related PO and delete the ItemReceipt for the item we
                      returned.
                    </Typography>
                    <Typography component="li" variant="overline">
                      In Netsuite Cancel the PO.
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  multiline
                  rows={3}
                  onChange={e => setBuyersNote(e.currentTarget.value)}
                  value={buyersNote}
                  name="buyersNote"
                  placeholder="Message to Seller about why we are not letting them get their item back."
                  label="Buyers Note"
                />
              </Grid>
              <Grid container item xs={12}>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confirmStepComplete}
                        onChange={(e: any) => {
                          const { checked } = e.target;
                          setConfirmStepComplete(checked);
                        }}
                        name="confirmRevokeReady"
                        color="primary"
                      />
                    }
                    name="confirmRevokeReady"
                    label="Steps have been completed"
                    labelPlacement="end"
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {updatedCancellationStatus === ConsignmentCancellationStatus.CANCELLED && (
            <Grid container item xs={12} rowSpacing={3}>
              <Grid item xs={12}>
                <Alert severity="warning" sx={{ mt: 1, width: '60%' }}>
                  <Typography variant="body1">
                    Warning: This is a Seller initiated action, if we need to cancelled the
                    Consignment Cancellation request you should use `Revoke`.
                  </Typography>
                </Alert>
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : consignmentCancellations.length < 1 ? (
        <Grid item xs={12}>
          <Typography>There are no Consignment Cancellations related to this Submission</Typography>
        </Grid>
      ) : consignmentCancellations.length > 1 ? (
        <Grid item xs={12}>
          <StripedDataGrid
            rowHeight={100}
            rows={consignmentCancellations}
            columns={columns}
            disableRowSelectionOnClick={true}
            rowSelectionModel={rowSelectionModel}
            checkboxSelection={true}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <LabelValuePairDisplay
            gridSize={{ label: 3, value: 9 }}
            labelValuePairs={[
              {
                label: 'Cancellation Status',
                value: consignmentCancellations[0]?.cancellationStatus,
              },
              {
                label: 'Id',
                value: consignmentCancellations[0]?.id,
              },
              {
                label: 'Return Address',
                value: (
                  <Typography>
                    <Typography>
                      {consignmentCancellations[0]?.firstName}{' '}
                      {consignmentCancellations[0]?.lastName}
                    </Typography>
                    <Typography>{consignmentCancellations[0]?.address}</Typography>
                    {consignmentCancellations[0]?.address2 && (
                      <Typography>{consignmentCancellations[0]?.address2}</Typography>
                    )}
                    <Typography>
                      {consignmentCancellations[0]?.city}, {consignmentCancellations[0]?.state}{' '}
                      {consignmentCancellations[0]?.zip} {consignmentCancellations[0]?.country}
                    </Typography>
                  </Typography>
                ),
              },
              {
                label: 'Phone',
                value: consignmentCancellations[0]?.phone,
              },
              {
                label: 'FedEx Tracking',
                value: consignmentCancellations[0]?.shippingTrackingNumber,
                url: consignmentCancellations[0]?.shippingTrackingUrl,
                copy: {
                  value: consignmentCancellations[0]?.shippingTrackingNumber ?? '',
                  hint: 'Copy FexEx Tracking Number',
                },
              },
              {
                label: 'Label',
                value: consignmentCancellations[0]?.shippingLabelUrl ? 'View' : null,
                url: consignmentCancellations[0]?.shippingLabelUrl ?? '',
                copy: {
                  value: consignmentCancellations[0]?.shippingLabelUrl ?? '',
                  hint: 'Copy Label URL',
                },
              },
              {
                label: 'Shopify Draft Order Id',
                value: consignmentCancellations[0]?.shopifyDraftOrderId,
              },
              {
                label: 'Shopify Draft Order Invoice Url',
                value: consignmentCancellations[0]?.shopifyDraftOrderInvoiceUrl,
              },
              {
                label: 'Created',
                value: new Date(consignmentCancellations[0]?.created).toLocaleString('en-US', {
                  timeZone: 'America/Denver',
                }),
              },
              {
                label: 'Modified',
                value: new Date(consignmentCancellations[0]?.modified).toLocaleString('en-US', {
                  timeZone: 'America/Denver',
                }),
              },
            ]}
          />
        </Grid>
      )}
      {editingCancellation && (
        <Grid item xs={12} sx={{ textAlign: 'right', mt: 2 }}>
          <Button
            color={loading ? 'info' : 'success'}
            variant="contained"
            disabled={loading || !canSubmit || !!!updatedCancellationStatus}
            endIcon={loading && <CircularProgress color={'inherit'} size={'16px'} />}
            onClick={() => handleSubmit()}
          >
            {loading ? 'Updating' : !!updatedCancellationStatus ? saveButtonText : '...'}
          </Button>
          <Button
            color="success"
            variant="outlined"
            disabled={loading}
            onClick={() => setEditingCancellation(undefined)}
            sx={{ ml: 1 }}
          >
            Cancel
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ConsignmentCancellationForm;
