import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import axios from '../../utils/axios';
import { API_URL } from '../../constants';
import '../../styles/tradeups.css';
import Modal from '../../components/modals/genericFormModal';
import cpoShield from '../../images/cpo-shield.svg';
import { styleStatus } from './helper';
import { StripedDataGrid } from '../../styledComponents/datagrid';
import { renderCellExpand } from '../../components/library/DataGrid/renderCellExpand';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridInputRowSelectionModel,
  GridPagination,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridValueGetterParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import { useKeyPress } from '../../utils/hooks/useKeyPress';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  Typography,
} from '@mui/material';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete, Button, TextField } from '../../components/library';
import { PageWrapper } from '../../styledComponents/wrappers';

export const TRADEUP_SEARCH_ROUTE = '/buyers/tradeups';

export interface SearchFilters {
  searchTerm: string;
  partner: string;
  type: string;
  buyer: string;
  status: string;
  includeAutoRejected: boolean;
}

const SubmissionListView: React.FC = () => {
  const [searchInitiated, setSearchInitiated] = useState<boolean>(false);
  const [storeSearch, setStoreSearch] = useState<boolean>(false);
  const [submissions, setSubmissions] = useState<any[]>([]);
  const [partners, setPartners] = useState<any[]>([]);
  const [buyers, setBuyers] = useState<any[]>([]);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [types] = useState<any[]>([
    { value: 'external', display: 'external' },
    { value: 'internal', display: 'internal' },
    { value: 'cpo', display: 'cpo' },
    { value: 'regular', display: 'regular' },
  ]);
  const [resultCount, setResultCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [bulkDeclineIds, setBulkDeclineIds] = useState<GridInputRowSelectionModel>();
  const [displayBulkDeclineModal, setDisplayBulkDeclineModal] = useState<boolean>(false);
  const [bulkProcessing, setBulkProcessing] = useState<boolean>(false);
  const [bulkSuccess, setBulkSuccess] = useState<boolean>(false);
  const [bulkError, setBulkError] = useState<boolean>(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });
  const [filters, setFilters] = useState<SearchFilters>({
    searchTerm: '',
    partner: '',
    type: '',
    buyer: '',
    status: '',
    includeAutoRejected: false,
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
    brokerId: false,
    status: false,
  });

  const history = useHistory();
  useKeyPress({ activationKey: 'escape' }, () => setDisplayBulkDeclineModal(false));

  const fetchPartners = async () => {
    const url = `${API_URL}/tradeup/partners`;
    const response = await axios.get(url);
    const partners = response?.data.reduce(
      (accu: any, partner: { id: any; name: any }) => {
        const newObj = {
          value: `${partner.id}`,
          display: partner.name,
        };
        return [...accu, newObj];
      },
      [{ value: 0, display: 'SYB' }],
    );

    setPartners(partners);
  };

  const fetchBuyers = async () => {
    const url = `${API_URL}/tradeup/getBuyers`;
    const response = await axios.get(url);
    const buyers = response?.data.reduce((accu: any, buyer: { email_address: any; name: any }) => {
      const newObj = {
        value: buyer.email_address,
        display: buyer.name,
      };
      if (accu.some((o: any) => o.value === buyer.email_address)) {
        return accu;
      } else {
        return [...accu, newObj];
      }
    }, []);
    setBuyers(buyers);
  };

  const fetchStatuses = async () => {
    const url = `${API_URL}/tradeup/statuses`;
    const response = await axios.get(url);
    const statuses = response?.data.reduce((accu: any, status: { id: any; status: any }) => {
      const newObj = {
        value: `${status.id}`,
        display: status.status,
      };
      return [...accu, newObj];
    }, []);
    setStatuses(statuses);
  };

  const fetchData = useCallback(async () => {
    if (!searchInitiated) return;
    setLoading(true);
    const avoidNaN = (n: any) => (Number.isNaN(n) ? null : n);
    const statusId = avoidNaN(parseInt(filters.status, 10));
    const sortBy = sortModel?.[0]?.field ?? 'createdAt';
    // Sort 'initiated' and 'resubmitted' ASC so because buyers do FIFO
    const sortOrder = sortModel?.[0]?.sort
      ? sortModel[0].sort.toUpperCase()
      : [1, 8].includes(statusId)
      ? 'ASC'
      : 'DESC';

    const url = `${API_URL}/tradeup/syb/submissions`;
    const res = await axios.get(url, {
      params: {
        page: paginationModel.page,
        sort: sortBy,
        sortOrder: sortOrder,
        searchTerm: filters.searchTerm,
        origin: avoidNaN(parseInt(filters.partner, 10)),
        type: parseInt(filters.partner, 10) === 0 ? filters.type : '',
        buyer: filters.buyer,
        statusId: statusId,
        includeAutoRejected: filters.includeAutoRejected,
      },
    });

    const { submissions, totalCount } = res?.data;
    setSubmissions(submissions);
    setResultCount(totalCount);
    setLoading(false);
    setSearchInitiated(false);
  }, [filters, paginationModel, sortModel, searchInitiated]);

  const submitDeclines = async (declinedSubs: any[] = []) => {
    setBulkProcessing(true);
    const url = `${API_URL}/tradeup/bulkDecline`;
    try {
      const response = await axios.post(url, declinedSubs);
      if (response && response.status < 300) {
        setBulkSuccess(true);
      } else {
        setBulkError(true);
      }
    } catch (e) {
      console.error(e);
      setBulkError(true);
    } finally {
      setBulkProcessing(false);
    }
  };

  const declineForm = () => {
    const declinedSubsArray = bulkDeclineIds || [];
    const declinedSubs = submissions.filter(sub =>
      (declinedSubsArray as number[]).find((key: number) => sub.id == key),
    );
    return (
      <Box className="decline-modal">
        <Typography variant="h6" className="text-center">
          Are you sure you want to decline these submissons?
        </Typography>
        <Box className="sub-list">
          {declinedSubs.map((sub, idx) => (
            <p className="decline-sub" key={idx}>
              {sub.partnerSite?.brandName ?? 'SYB'} - {sub.id} - {sub.firstName || ''}{' '}
              {sub.lastName || ''} - {sub.items[0]?.brand || ''} {sub.items[0]?.model || ''}
            </p>
          ))}
        </Box>

        <Button
          ordinality="primary"
          className="submit-button"
          onClick={() => submitDeclines(declinedSubs)}
          endIcon={bulkProcessing && <CircularProgress color={'inherit'} size={'16px'} />}
          disabled={bulkProcessing || bulkSuccess || bulkError}
        >
          Decline
        </Button>
      </Box>
    );
  };

  const onRowClick = (params: GridRowParams, e: any) => {
    const { id } = params;
    const url = `/tradeups/submission/${id}`;
    if (e.metaKey) {
      const newTab = window.open(url, '_blank');
      newTab?.focus();
    } else {
      history.push(url);
    }
  };

  const customFooter = () => {
    return <Box sx={{ py: 2 }}></Box>;
  };

  const customToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: 'right' }}>
        <GridToolbarColumnsButton />
        {((!Array.isArray(bulkDeclineIds) && !!bulkDeclineIds) ||
          (Array.isArray(bulkDeclineIds) && !!bulkDeclineIds?.length)) && (
          <Button
            ordinality="primary"
            className="classy-button"
            onClick={() => setDisplayBulkDeclineModal(true)}
            style={{ marginBottom: '10px' }}
          >
            Decline
          </Button>
        )}

        <GridPagination sx={{ mx: 4 }} />
      </GridToolbarContainer>
    );
  };

  const handleChangeStoreSearch = (e: any) => {
    const { checked } = e.target;
    setStoreSearch(checked);
  };

  // #region filter functions

  const handleFilterClear = async (e: any) => {
    e.preventDefault();
    setFilters({
      searchTerm: '',
      partner: '',
      type: '',
      buyer: '',
      status: '',
      includeAutoRejected: false,
    });
  };

  const handleFilterSubmit = async (e: any) => {
    e.preventDefault();
    setSearchInitiated(true);
  };

  // #endregion filter functions

  useEffect(() => {
    fetchBuyers();
    fetchPartners();
    fetchStatuses();
    const storedColumns = window.localStorage.getItem('submissionsSearchColumns');
    if (storedColumns) {
      const submissionsSearchColumns = JSON.parse(storedColumns);
      setColumnVisibilityModel(submissionsSearchColumns);
    }

    const submissionsSearch = window.localStorage.getItem('submissionsSearch');
    if (submissionsSearch) {
      const storedFilters = JSON.parse(submissionsSearch);
      setStoreSearch(true);
      setFilters(storedFilters);
      setSearchInitiated(true);
    }
  }, []);

  useEffect(() => {
    async function updateData() {
      await fetchData();
    }

    updateData();
  }, [fetchData, paginationModel, sortModel, searchInitiated]);

  useEffect(() => {
    if (storeSearch) {
      const stringColumns = JSON.stringify(filters);
      window.localStorage.setItem('submissionsSearch', stringColumns);
    } else {
      window.localStorage.removeItem('submissionsSearch');
    }
  }, [storeSearch, filters]);

  useEffect(() => {
    const stringColumns = JSON.stringify(columnVisibilityModel);
    window.localStorage.setItem('submissionsSearchColumns', stringColumns);
  }, [columnVisibilityModel]);

  useKeyPress({ activationKey: 'enter' }, () => setSearchInitiated(true));

  const columns: GridColDef[] = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 1,
      sortable: false,
    },
    {
      headerName: 'Origin',
      field: 'partnerSite',
      renderCell: (params: GridRenderCellParams) => (
        <Typography component="span">
          {params.value?.brandName ?? 'SYB'}
          {params.row.isCpo && (
            <img
              style={{
                height: '25px',
                display: 'inline-block',
                marginLeft: '10px',
                verticalAlign: 'middle',
              }}
              src={cpoShield}
              alt="cpo shield"
            />
          )}
        </Typography>
      ),
      flex: 1,
      sortable: false,
    },
    {
      headerName: 'Submission Id',
      field: 'id',
      flex: 2,
    },
    {
      headerName: 'Seller Name',
      field: 'name',
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.firstName} ${params.row.lastName}`,
      renderCell: renderCellExpand,
      flex: 2,
      sortable: false,
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography
            sx={{
              textTransform: 'capitalize',
            }}
          >
            <Typography
              component="span"
              sx={{
                color: styleStatus(params.value),
                marginRight: '5px',
                fontSize: 20,
              }}
            >
              &#x25cf;
            </Typography>
            {params.value}
          </Typography>
        );
      },
      flex: 2,
      sortable: false,
    },
    {
      headerName: 'Buyer Name',
      field: 'buyer',
      valueGetter: (params: GridValueGetterParams) => params.value?.name,
      renderCell: renderCellExpand,
      flex: 2,
    },
    {
      headerName: 'Dealer Name',
      field: 'dealer',
      valueGetter: (params: GridValueGetterParams) => params.value?.name,
      renderCell: renderCellExpand,
      flex: 2,
    },
    {
      headerName: 'Item',
      field: 'item',
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.items[0]?.brand || ''} ${params.row.items[0]?.model || ''} ${
          params.row.items[0]?.year ? `(${params.row.items[0]?.year})` : ''
        }`,
      renderCell: renderCellExpand,
      flex: 4,
      sortable: false,
    },
    {
      headerName: 'Created',
      field: 'createdAt',
      renderCell: (params: GridRenderCellParams) => {
        const hoverValue = new Date(params.value).toLocaleString('en-US', {
          timeZone: 'America/Denver',
        });
        params.value = new Date(params.value).toLocaleDateString('en-US', {
          timeZone: 'America/Denver',
        });
        return renderCellExpand(params, hoverValue);
      },
      flex: 2,
    },
  ];

  return (
    <PageWrapper style={{ overflow: 'hidden' }} className="tradeup-list-table">
      <Grid container direction="row" spacing={2} justifyContent="start">
        <Grid container item xs={8} spacing={1}>
          <Grid container item xs={12} md={6} spacing={1}>
            <Grid item xs={12} lg={6}>
              <TextField
                size="small"
                id="searchTerm"
                value={filters.searchTerm}
                onChange={e =>
                  setFilters({
                    ...filters,
                    searchTerm: e.target.value,
                  })
                }
                placeholder="Search by dealer name/email"
                autoFocus
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Autocomplete
                inputProps={{
                  label: 'Status',
                  variant: 'outlined',
                  value: filters.status,
                  placeholder: 'Filter by status',
                  size: 'small',
                }}
                getOptionLabel={status => status?.display || ''}
                options={statuses}
                value={statuses.find(status => status.value === filters.status) || null}
                onChange={(_e, option) => {
                  setFilters(prevState => ({ ...prevState, status: option?.value }));
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} spacing={1}>
            <Grid item xs={12} lg={6}>
              <Autocomplete
                inputProps={{
                  label: 'Buyers',
                  variant: 'outlined',
                  value: filters.buyer,
                  placeholder: 'Filter by buyers',
                  size: 'small',
                }}
                getOptionLabel={buyer => buyer?.display || ''}
                options={buyers}
                value={buyers.find(buyer => buyer.value === filters.buyer) || null}
                onChange={(_e, option) => {
                  setFilters(prevState => ({ ...prevState, buyer: option?.value }));
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Autocomplete
                inputProps={{
                  label: 'Partners',
                  variant: 'outlined',
                  value: filters.partner,
                  placeholder: 'Narrow by partner',
                  size: 'small',
                }}
                getOptionLabel={partner => partner?.display || ''}
                options={partners}
                value={partners.find(partner => partner.value === filters.partner) || null}
                onChange={(_e, option) => {
                  setFilters(prevState => ({ ...prevState, partner: option?.value }));
                }}
              />
            </Grid>
          </Grid>
          {parseInt(filters.partner, 10) === 0 && (
            <Grid container item xs={12} spacing={1} justifyContent="flex-end">
              <Grid item xs={12} lg={3} spacing={1}>
                <Autocomplete
                  inputProps={{
                    label: 'Type',
                    variant: 'outlined',
                    value: filters.type,
                    placeholder: 'Narrow by type of submission',
                    size: 'small',
                  }}
                  getOptionLabel={type => type?.display || ''}
                  options={types}
                  value={types.find(type => type.value === filters.type) || null}
                  onChange={(_e, option) => {
                    setFilters(prevState => ({ ...prevState, type: option?.value }));
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container item xs={4} spacing={1}>
          <Grid container item xs={12} md={6} spacing={1}>
            <Grid item xs={12} xl={6}>
              <Button
                fullWidth
                ordinality="secondary"
                disabled={loading}
                variant="contained"
                endIcon={loading && <CircularProgress color={'inherit'} size={'16px'} />}
                onClick={handleFilterSubmit}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={12} xl={6}>
              <Button
                fullWidth
                ordinality="secondary"
                disabled={loading}
                variant="text"
                endIcon={loading && <CircularProgress color={'inherit'} size={'16px'} />}
                onClick={handleFilterClear}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} spacing={1}>
            <Grid item xs={12}>
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    onChange={handleChangeStoreSearch}
                    name="storeSearch"
                    checked={!!storeSearch}
                    color="success"
                  />
                }
                label={<Typography variant="body2">Reload Search</Typography>}
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    onChange={e => {
                      setFilters(prevState => ({
                        ...prevState,
                        includeAutoRejected: e.target.checked,
                      }));
                    }}
                    name="includeAutoRejected"
                    checked={!!filters.includeAutoRejected}
                    color="success"
                  />
                }
                label={<Typography variant="body2">Include Auto-Rejected</Typography>}
                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ mx: 'auto', my: 2 }}>
        <StripedDataGrid
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'none',
            },
            height: !!!submissions.length ? '30rem' : 'auto',
            my: 3,
          }}
          height={!!!submissions.length ? '30rem' : 'auto'}
          searchNotInitiated={!searchInitiated}
          columns={columns}
          rows={submissions}
          rowCount={resultCount}
          loading={loading}
          onRowClick={onRowClick}
          checkboxSelection
          onRowSelectionModelChange={newRowSelectionModel =>
            setBulkDeclineIds(newRowSelectionModel)
          }
          rowSelectionModel={bulkDeclineIds}
          disableDensitySelector={true}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={paginationModel => {
            setPaginationModel(paginationModel);
            setSearchInitiated(true);
          }}
          pageSizeOptions={[25]}
          sortingMode="server"
          sortingOrder={['desc', 'asc', null]}
          sortModel={sortModel}
          onSortModelChange={model => {
            setSortModel(model);
            setSearchInitiated(true);
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
          slots={{
            toolbar: customToolbar,
            footer: customFooter,
          }}
        />
      </Box>
      {displayBulkDeclineModal && (
        <Modal
          success={bulkSuccess}
          error={bulkError}
          closeModal={() => {
            if (bulkSuccess) {
              setSearchInitiated(true);
            }
            setDisplayBulkDeclineModal(false);
            setBulkSuccess(false);
            setBulkError(false);
          }}
          form={declineForm()}
        />
      )}
    </PageWrapper>
  );
};

export default withRouter(SubmissionListView);
